import * as React from "react";
import * as emotion from "emotion";

import {AccountModule} from "@intuitionrobotics/user-account/frontend";
import {LoginModule} from "@modules/LoginModule";
import {QueryParam_RedirectUrl} from "@intuitionrobotics/user-account/shared/api";
import {
    LoginsParent,
    mobileBackgroundStyle
} from "./LoginsParent";
import {Loader} from "../ui/Loader";
import {Component_MobileLogin} from "../components/Component_MobileLogin";

const containerStyle = emotion.css`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  margin: auto;
  pointer-events: unset;
`;

const rectangleStyle = emotion.css`
  color: rgb(131, 146, 166);
  height: 100%;
  width: 100%;
  justify-content: center;
`;

export const roundedButton = emotion.css`
	height: 38px;
	border-radius: 25px;
	background-color: #9c9ccd;
	border-width: 0;
	text-align: left;
	padding: 0 15px;
	color: white;
	margin-top: 10px;
`;

export const loginTitleColor = "#294685";

const emailValidationRe = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const buttonStyle = emotion.css({
    "backgroundColor": "#487CFF",
    "borderWidth": "0",
    borderRadius: 10,
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: "-0.21px",
    "textAlign": "center",
    "padding": "0 15px",
    "color": "#fbfbfb",
    "marginTop": "20px",
    marginBottom: 25,
    outline: "none",
    ":active": {
        backgroundColor: "#0081b5"
    }
});

const grayLine = emotion.css`
	width: 93%;
	height: 1px;
	margin: 2px;
`;

export class Login_Mobile
    extends LoginsParent<{}, { loading: boolean }> {
    constructor(props: {}) {
        super(props, 'IR Login');
        this.state = {
            loading: false
        }
    }

    renderContent = () => {
        return <div id="login-mobile" className={mobileBackgroundStyle} style={{height: "100%", width: "100%", position: "fixed", top: 0, bottom: 0, backgroundColor: "white"}}>
            <div className={`full_screen center_v center_h ${containerStyle}`}>
                <div className={`ll_v_c ${rectangleStyle}`}>
                    {this.renderUserLogin()}
                    <div className={`ll_h_c`} style={{marginBottom: 0, width: "100%"}}>
                        <div className={grayLine}/>
                        <span style={{padding: "0px 7px", color: loginTitleColor, fontWeight: 600, fontSize: "18px", lineHeight: "21.48px"}}>OR</span>
                        <div className={grayLine}/>
                    </div>
                    {this.renderSamlBtn()}
                </div>
                {this.state.loading && <Loader overlay={true}/>}
            </div>
        </div>;
    };

    private renderUserLogin = () => {
        return <>
            <div style={{color: loginTitleColor, fontWeight: 600, fontSize: "28px", lineHeight: "34px", marginBottom: "30px"}}>Welcome!</div>
            <Component_MobileLogin
                validate={(data => {
                    const errs: { [field: string]: string } = {};
                    if (!data.email || !emailValidationRe.test(data.email))
                        errs['email'] = '- Please provide a valid email address';
                    if (!data.password || data.password.length < 8)
                        errs['password'] = '- Please enter a password at least 8 characters';

                    return errs;
                })}
            />
            {/*<div className={redirectLabelStyle}>*/}
            {/*	New here? <a href={RoutingModule.getRoute(Route_Register).path} className={hrefStyle}>Register</a>*/}
            {/*</div>*/}
        </>;
    };

    private renderSamlBtn = () => {
        const loginContext = {
            [QueryParam_RedirectUrl]: LoginModule.getSamlRedirectUrl()
        };
        return <div className="ll_v_c" style={{color: "white", width: "100%"}}>
            <button onClick={() => {
                AccountModule.loginSAML(loginContext);
                this.setState({loading: true})
            }} className={`clickable ${buttonStyle}`} style={{width: "80%", height: 50}}>
                Login with IR account
            </button>
        </div>;
    };

}
