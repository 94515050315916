import * as React from "react";
import {BaseComponent, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {ApiCaller_DfAgentsBackup, OnDfAgentsBackupLoaded} from "@modules/ApiCaller_DfAgentsBackup";
import * as emotion from "emotion";
import {clickedRowStyle, regularBtnStyle} from "../css/permissionsCss";
import * as domainsCss from "../permissions/permissions-domains/css/domainsCss";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {DB_DFAgents, DfAgentBackup, DialogFlow_RestoreAgent} from "@app/ir-q-app-common/types/df-agents";
import {Loader} from "../../ui/Loader";
import {Format_YYYYMMDD_HHmmss, formatTimestamp} from "@intuitionrobotics/ts-common";

const shortRowItem = emotion.css`
	margin-right: 10px;
	width: 60px;
	display: inline-block;
	white-space: nowrap;
`;

const midRowItem = emotion.css`
	margin-right: 10px;
	width: 150px;
	display: inline-block;
	white-space: nowrap;
`;

const longRowItem = emotion.css`
	margin-right: 10px;
	width: 220px;
	display: inline-block;
	white-space: nowrap;
`;

type State = {
	selectedAgentFromPool?: DB_DFAgents,
	selectedBackup?: DfAgentBackup,
	showLoader: boolean
}

export class BackedUpDfAgents
	extends BaseComponent<{}, State>
	implements OnDfAgentsBackupLoaded {
	private dfBackups: DfAgentBackup[] = [];

	constructor(props: {}) {
		super(props);
		this.state = {
			showLoader: false
		}
	}

	componentDidMount() {
		ApiCaller_DfAgentsBackup.query();
	}

	__onDfAgentsBackupLoaded = () => this.forceUpdate();

	restoreAgent = () => {
		const {selectedBackup, selectedAgentFromPool} = this.state;
		if (!selectedBackup || !selectedAgentFromPool) {
			ToastModule.toastError("Choose agent and backup first");
			return;
		}

		const agentProjectId = selectedAgentFromPool.projectId;
		this.setState({showLoader: true});
		XhrHttpModule
			.createRequest<DialogFlow_RestoreAgent>(HttpMethod.POST, 'restore-agent')
			.setRelativeUrl("/v1/dialogflow/restore-agent")
			.setJsonBody({backupPath: selectedBackup.backupPath, agentProjectId})
			.setLabel(`Restoring agent: project id '${agentProjectId}'`)
			.setOnSuccessMessage(`Agent: project id '${agentProjectId}' has restored successfully`)
			.setOnError(`Error restoring agent: project id '${agentProjectId}'`)
			.execute(async () => {
				this.setState({showLoader: false, selectedAgentFromPool: undefined, selectedBackup: undefined});
			});
	}

	renderLoader() {
		const {showLoader} = this.state;
		if (!showLoader)
			return;

		return <Loader/>;
	}

	render() {
		this.dfBackups = ApiCaller_DfAgentsBackup.getDgAgentsBackups().sort((a, b) => (a.timestamp - b.timestamp));
		const dfBackupItems = this.dfBackups.map(dfBackup => {
			const selectedBackupId = this.state.selectedBackup && this.state.selectedBackup._id;
			return <div className={selectedBackupId === dfBackup._id ? clickedRowStyle: domainsCss.domainItemStyle} key={dfBackup._id} onClick={() => {
				this.setState({selectedBackup: dfBackup})
			}}>
				<span className={longRowItem}>{dfBackup.agentName}</span>
				<span className={midRowItem}>{formatTimestamp(Format_YYYYMMDD_HHmmss, dfBackup.timestamp)}</span>
				<span className={shortRowItem}>{dfBackup.env}</span>
				<span className={shortRowItem}>{dfBackup.version}</span>
			</div>
		});

		return <div style={{marginTop: "50px"}} className={`ll_v_c`}>
			<div>
			<label style={{width: "200px", marginBottom: "7px", display: "inline-block", fontWeight: "bold"}}>Select backup content:</label>
			<div style={{marginBottom: "30px"}}>{dfBackupItems}</div>
				<div className={`ll_v_c`}>
					<button onClick={() => this.restoreAgent()} className={regularBtnStyle}>Restore</button>
				</div>
			</div>
			{this.renderLoader()}
		</div>
	}
}
