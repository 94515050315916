import * as React from "react";
import {
    DialogButton_Cancel,
    DialogButton_Submit,
    DialogModule,
    ToastModule,
    TS_CheckboxField
} from "@intuitionrobotics/thunderstorm/frontend";
import {toggleElementInArray} from "@intuitionrobotics/ts-common";
import {css} from "emotion";
import {Unit} from "@app/ir-q-app-common/types/units";

type BatchActionsType = {
    label?: string
    action: (units: Unit[]) => void
}

type Props = {
    action: BatchActionsType,
    units: Unit[],
    explain?: string
}

type State = {
    finalUnits: Unit[],
}

const contentStyle = css({
    padding: "10px 40px"
});

const confirmationButtons = (onSubmit: () => void, label?: string) => {
    const cancel = DialogButton_Cancel(DialogModule.close)
        .addStyle({border: "none", cursor: "pointer"});
    const submit = DialogButton_Submit(onSubmit, label)
        .addStyle({border: "none", cursor: "pointer"});
    return [cancel,
        submit];
}


export class BatchActionsDialogContent
    extends React.Component<Props, State> {

    state = {
        finalUnits: [...this.props.units]
    };


    render() {
        const amount = this.state.finalUnits.length;
        return <div className={contentStyle}>
            {this.props.explain && <div>{this.props.explain}</div>}
            {amount ? <div style={{paddingBottom: 4}}>on {amount} unit{amount > 1 ? "s" : ""}:</div> :
                <div>All units unchecked</div>}
            <TS_CheckboxField
                options={this.props.units.map(unit => ({value: unit}))}
                value={this.state.finalUnits}
                label={unit => unit.value.unitId}
                gridColumns={2}
                // checkboxWidth={"10px"}
                fieldContainerClass={css({maxHeight: 150, overflowY: "auto"})}
                onCheck={unit => {
                    this.setState(prev => {
                        toggleElementInArray(prev.finalUnits, unit);
                        return prev;
                    });
                }}
            />
            <div className={`ll_h_c`} style={{
                marginTop: "auto",
                justifyContent: "flex-end",
                padding: "12px 0"
            }}>
                {confirmationButtons(this.onSubmit, "Go!").map(
                (button, idx) => <div key={idx}>
                    <button
                        className={button.className}
                        style={button.style}
                        onClick={button.action}>{button.content}</button>
                </div>)}
            </div>
        </div>;
    };

    private onSubmit = () => {
        const _units = this.state.finalUnits;
        if (_units.length === 0)
            return ToastModule.toastError('You unchecked all units.Please select some units first');

        DialogModule.close();
        this.props.action.action(_units);
    };
}


