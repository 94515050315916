

/**
 * Created by tacb0ss on 28/07/2018.
 */
module.exports = {
	Text_HelloWorld: "NL: Hello World",
	Text_About: "NL: About",
	Text_Home: "NL: Home"
};
