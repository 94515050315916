import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {PermissionsTabs} from "./PermissionsTabs";
import {
	ApiCaller_PermissionsGroup,
	ApiCaller_PermissionsProject,
	ApiCaller_PermissionsUser,
	OnPermissionsGroupsLoaded,
	OnPermissionsProjectsLoaded,
	OnPermissionsUsersLoaded
} from "@intuitionrobotics/permissions/frontend";
import {Loader} from "../ui/Loader";
import {
	AccountModule,
	OnAccountsLoaded
} from "@intuitionrobotics/user-account/frontend";
import * as projectsCss from "./permissions/permissions-projects/css/permissionsProjectsCss";
import {AccountsList} from "./permissions/permissions-users/AccountsList";

export class Page_Permissions_Users
	extends BaseComponent
	implements OnPermissionsUsersLoaded, OnPermissionsGroupsLoaded, OnPermissionsProjectsLoaded, OnAccountsLoaded {

	constructor(props: {}) {
		super(props);
	}

	__onAccountsLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsUsersLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsGroupsLoaded = () => {
		this.forceUpdate();
	}

	__onPermissionsProjectsLoaded = () => {
		this.forceUpdate();
	};

	componentDidMount() {
		document.title = "Permission Users";
		ApiCaller_PermissionsProject.query();
		ApiCaller_PermissionsUser.query();
		ApiCaller_PermissionsGroup.query();
		AccountModule.listUsers();
	}

	render() {
		if (ApiCaller_PermissionsUser.getUsers().length === 0)
			return <Loader/>;

		if (ApiCaller_PermissionsGroup.getGroups().length === 0)
			return <Loader/>;

		if (ApiCaller_PermissionsProject.getProjects().length === 0)
			return <Loader/>;

		if (AccountModule.getAccounts().length === 0)
			return <Loader/>;

		return <>
			<div className={projectsCss.projectsPageContainer}>
				<div className={projectsCss.projectsTitleStyle}>USERS PERMISSIONS</div>
				<div className={projectsCss.sectionsWrapperStyle}>
					<div className={projectsCss.projectsListContainer}>
						<AccountsList/>
					</div>
				</div>
			</div>
			<PermissionsTabs/>
		</>;
	}
}
