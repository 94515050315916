import * as React from "react";
import * as emotion from "emotion";
import {BaseComponent, OnUnauthenticatedResponse} from "@intuitionrobotics/thunderstorm/frontend";
import {_keys} from "@intuitionrobotics/ts-common";
import {Request_LoginAccount} from "@intuitionrobotics/user-account/shared/api";
import {
	AccountModule,
	RequestKey_AccountLogin
} from "@intuitionrobotics/user-account/frontend";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {NewStyledInput} from "./NewStyledInput";

const icons = {
	avatar: require('@res/images/icn-avatar.svg'),
	lock: require('@res/images/icn-lock.svg')
};

export type ValueAndError = {
	value?: string
	error?: string
}

type State<T> = {
	data: Partial<T>
	errors?: { [index: string]: string },
	errMessages?: string[]
}

type Props<T> = {
	validate?: (data: Partial<T>) => { [field: string]: string }
}

const style = emotion.css({
	                          "height": "50px",
	                          "backgroundColor": "#224270",
	                          "borderWidth": "0",
	                          borderRadius: 10,
	                          fontSize: 18,
	                          fontWeight: 600,
	                          letterSpacing: "-0.21px",
	                          "textAlign": "center",
	                          "padding": "0 15px",
	                          "color": "#fbfbfb",
	                          "marginTop": "10px",
	                          marginBottom: 25,
	                          outline: "none",
	                          ":active": {
		                          backgroundColor: "#001636"
	                          }
                          });

const errMessageStyle = emotion.css({
  borderRadius: 4,
	boxSizing: "border-box",
  backgroundColor: "rgba(222, 55, 40, 0.2)",
  color: "#de3728",
  minHeight: 54,
  fontSize: 11,
  fontStyle: 'italic',
  letterSpacing: "-0.16px",
  marginBottom: 23,
  padding: "6px 13px",
	minWidth: 220
});

type InputField = {
	label: string
	hint: string
	type: 'text' | 'number' | 'password'
}

type Form<T> = { [K in keyof T]: InputField & { icon?: string } }

const form: Form<Omit<Request_LoginAccount, "frontType">> = {
	email: {
		type: "text",
		hint: "a valid email address",
		label: "Username",
		icon: icons.avatar
	},
	password: {
		type: "password",
		hint: "at least 8 characters",
		label: "Password",
		icon: icons.lock
	}
};

export class Component_MobileLogin
	extends BaseComponent<Props<Request_LoginAccount>, State<Request_LoginAccount>>
	implements OnRequestListener, OnUnauthenticatedResponse {

	state = {
		data: {} as Partial<Request_LoginAccount>,
		errors: {} as { [index: string]: string },
		errMessages: []
	};

	onUnauthenticatedResponse = () => {
		this.setState(() => ({
			errMessages: ['Incorrect username or password.',
				'Please check and try again.']
		}))
	};

	__onRequestCompleted = (key: string, success: boolean) => {
		if (success || key !== RequestKey_AccountLogin)
			return;

		this.setState(() => ({
			errMessages: ['Incorrect username or password.',
			              'Please check and try again.']
		}))
	};

	render() {
		const data = this.state.data;
		return <>
			{this.state.errMessages.length > 0 && <div className={errMessageStyle} style={{width: "80%"}}>
				Oops, wrong input:
				{this.state.errMessages.map((error, idx) => <div key={error}>{error}</div>)}
			</div>}
			<div style={{width: "80%"}}>
				{_keys(form).map(key => {
					                 const field = form[key];
					                 return <div key={key}><NewStyledInput
						                 id={key}
						                 value={data[key]}
						                 label={field.label}
						                 type={field.type}
						                 placeholder={field.hint}
						                 onChange={this.onValueChanged}
						                 onAccept={this.loginClicked}
						                 error={this.state.errors[key]}
					                 /></div>
				                 }
				)}
			</div>
			<div className={'ll_h_c'} style={{justifyContent: 'center', width: "100%"}}>
				<button onClick={this.loginClicked}
				        className={`${this.disabled() ? "" : "clickable"} ${style}`}
				        style={this.disabled() ? {backgroundColor: "#cbd3dd", width: "80%"} : {width: "80%"}}
				disabled={this.disabled()}>Login
				</button>
			</div>
		</>;
	}

	private disabled = () => {
		return !this.state.data.email || !this.state.data.password
		// if (emailValidationRe.test(this.state.data.email || '') && this.state.data.password && this.state.data.password.length > 7)
		// 	return false;
		// return true;
	};

	private onValueChanged = (value: string, id: keyof Omit<Request_LoginAccount, "frontType">) => {
		this.setState(state => {
			state.errors && delete state.errors[id];
			state.data[id] = value;
			return state
		});
	};

	private loginClicked = () => {

		const data: Partial<Request_LoginAccount> = this.state.data;
		const errors = this.props.validate && this.props.validate(data);
		const errMessages = errors ? _keys(errors).map(err => errors[err]) : [];

		this.setState(() => ({errors, errMessages}));

		if (errors && _keys(errors).length > 0)
			return;
		// return ToastModule.toastError(`Oops, wrong input:\n${errors.join("\n")}`);

		AccountModule.login(this.state.data as Request_LoginAccount)
	};
}
