import * as React from "react";
import * as domainsCss from "../../permissions-domains/css/domainsCss";
import {DB_PermissionDomain} from "@intuitionrobotics/permissions";
import {LevelsToUsersList} from "../levels/LevelsToUsersList";

type State = {
	displayHoverClass: boolean,
	editMode: boolean,
	inputDomainNameChange: boolean
}

type Props = {
	isOn: boolean,
	domain: DB_PermissionDomain,
	onDomainSelected: (src: string) => void;
}

export class DomainToUsersItem
	extends React.Component<Props, State> {
	private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

	constructor(props: Props) {
		super(props);
		this.state = {
			displayHoverClass: false,
			editMode: false,
			inputDomainNameChange: false
		}

		this.handleClickOnDomainName = this.handleClickOnDomainName.bind(this);
		this.handleDomainNameChange = this.handleDomainNameChange.bind(this);
	}

	setDisplayHoverClass(value: boolean) {
		const {isOn} = this.props;
		if (!isOn) {
			this.setState({displayHoverClass: value});
			return;
		}

		this.setState({displayHoverClass: false});
	}

	handleDomainSelected = () => {
		const {domain, onDomainSelected} = this.props;
		onDomainSelected(domain._id);
		this.setDisplayHoverClass(false);
	};

	handleDomainNameChange = () => {
		const {domain} = this.props;
		const domainName = this.inputRef?.current?.value;
		domain.namespace = domainName || '';
		this.setState({inputDomainNameChange: true});
	};

	handleClickOnDomainName = (event: React.MouseEvent) => {
		const {isOn} = this.props;
		if (!isOn)
			return;

		event.stopPropagation();
		this.setState({editMode: true});
	};

	renderDomainHeader() {
		const {isOn, domain} = this.props;

		return <div onClick={this.handleDomainSelected}
		            onMouseEnter={() => this.setDisplayHoverClass(true)}
		            onMouseLeave={() => this.setDisplayHoverClass(false)}
		            style={{position: "relative"}}
		            className={`${domainsCss.domainRow} ll_h_c`}>
			<div onClick={this.handleClickOnDomainName}
			     className={isOn ? domainsCss.domainOnNameStyle : domainsCss.domainNameStyle}>{domain.namespace || 'Input name'}</div>
		</div>;
	}

	renderPermissionLevels() {
		const {isOn, domain} = this.props;
		if (!isOn)
			return null;

		return <>
			<LevelsToUsersList filterByDomainId={domain._id}/>
		</>;
	}

	render() {
		const {isOn} = this.props;
		const {displayHoverClass} = this.state;

		const className = isOn ? domainsCss.domainOnItemStyle : (displayHoverClass ? domainsCss.domainHoverItemStyle : domainsCss.domainItemStyle);
		return <div style={{border: "none"}} className={className}>
			{this.renderDomainHeader()}
			{this.renderPermissionLevels()}
		</div>;
	}
}
