import * as React from "react";
import * as levelsCss from "../permissions-levels/css/permissionLevelsCss";
import * as permissionsUtils from "../permissions-utils";
import {PermissionBulletData} from "../permissions-levels/PermissionBulletData";
import {
	ApiCaller_PermissionsGroup,
	OnPermissionsGroupsLoaded,
	OnPermissionsUsersLoaded
} from "@intuitionrobotics/permissions/frontend";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {CustomDropdown} from "../../../components/CustomDropdown";
import * as apiCss from "../permissions-api/css/permissionsApiCss";
import {Loader} from "../../../ui/Loader";
import {
	DB_PermissionsGroup,
	User_Group
} from "@intuitionrobotics/permissions/shared/assign-types";
import {
	addItemToArray,
	BadImplementationException,
	removeItemFromArray
} from "@intuitionrobotics/ts-common";
import {CustomFields} from "../permissions-custom-fields/CustomFields";

const icon__add = require('@res/images/icon_add_blue.svg');

type State = {
	selectedGroup: User_Group,
	showLoader: boolean
}

type Props = {
	groups: User_Group[],
	multiUserMode?: boolean,
	onGroupsUpdate: () => void
}

export class UserGroups
	extends BaseComponent<Props, State>
	implements OnPermissionsGroupsLoaded, OnPermissionsUsersLoaded {
	private groups: DB_PermissionsGroup[];

	constructor(props: Props) {
		super(props);

		this.state = {
			selectedGroup: {groupId: ""},
			showLoader: false
		}

		this.groups = [];
	}

	__onPermissionsUsersLoaded = () => {
		this.setState({showLoader: false});
		this.forceUpdate();
	}

	__onPermissionsGroupsLoaded = () => {
		this.setState({showLoader: false});
		this.forceUpdate();
	}

	selectGroup = (groupId: string, groupRef?: User_Group) => {
		if (!groupRef)
			throw new BadImplementationException("Must pass removeByRefObj/groupRef parameter");

		this.setState({selectedGroup: groupRef});
	};

	removeGroup = (groupId: string, groupRef?: User_Group) => {
		if (!groupRef)
			throw new BadImplementationException("Must pass removeByRefObj/groupRef parameter");

		const {selectedGroup} = this.state;

		if (this.props.multiUserMode) {
			removeItemFromArray(this.props.groups, groupRef);
			this.forceUpdate();
		} else {
			this.setState({showLoader: true});
			removeItemFromArray(this.props.groups, groupRef);
			this.props.onGroupsUpdate();
		}

		if (selectedGroup.groupId === groupId) {
			this.setState({selectedGroup: {groupId: ""}});
		}
	};

	handleAddGroup = (group: User_Group) => {
		addItemToArray(this.props.groups, group);
		if (this.props.multiUserMode) {
			this.forceUpdate();
			return;
		}
		this.setState({showLoader: true});
		this.props.onGroupsUpdate();
	}

	onCustomFieldUpdate = () => {
		this.props.onGroupsUpdate();
	}

	renderGroupNames() {
		const {selectedGroup} = this.state;
		const {groups, multiUserMode} = this.props;
		this.groups = ApiCaller_PermissionsGroup.getGroups();

		const groupsItems = groups.map((groupItem, index) => {
			const isOn = groupItem.groupId === selectedGroup.groupId && groupItem.customField === selectedGroup.customField;
			const group = permissionsUtils.getGroup(groupItem.groupId, this.groups);
			if (!group)
				return null;

			return <PermissionBulletData onLevelDeleted={this.removeGroup} noConfirm={multiUserMode} onMethodSelected={this.selectGroup} isOn={isOn} key={index}
			                             bulletData={{_id: group._id, name: group.label}} refObj={groupItem}/>
		});

		return (<div className={`${levelsCss.methodsContainerStyle} ll_h_c`}>{groupsItems}</div>);
	}

	renderAddGroup() {
		const {selectedGroup} = this.state;
		if (selectedGroup.groupId)
			return null;

		return <div style={{marginBottom: '25px', marginTop: '5px'}}>
			<div style={{width: '300px'}}>
				<div className={apiCss.ddlLabelStyle}>Groups</div>
				<CustomDropdown menuDirection={'top'} placeholder={'Choose group from list'} onChange={(option) => {
					this.handleAddGroup({groupId: option.value});
				}} options={permissionsUtils.getDDlOptions("label", "_id", this.groups)}/>
			</div>
		</div>
	}

	renderNewLevel() {
		return <div id={"specific"} className={`${levelsCss.methodsContainerStyle} ${levelsCss.addMethodContainer}`}>
			<img alt="" onClick={() => {
				this.setState({selectedGroup: {groupId: ""}});
			}} className={levelsCss.addMethodIcon} src={icon__add}/>
		</div>;
	}

	renderLoader() {
		const {showLoader} = this.state;
		if (!showLoader)
			return;

		return <Loader/>;
	}

	renderCustomFields() {
		const {selectedGroup} = this.state;
		const existSelectedGroup = this.props.groups.find(group => (group.groupId === selectedGroup.groupId && group.customField === selectedGroup.customField));

		if (!existSelectedGroup)
			return null;

		existSelectedGroup.customField = existSelectedGroup.customField || {}
		return <>
			<div style={{color: '#3d474f'}}>Custom fields:</div>
			<CustomFields limitToOneCF={true} onCustomFieldsUpdate={this.onCustomFieldUpdate} customFields={[existSelectedGroup.customField]}/>
		</>;
	}

	render() {
		return <>
			<div style={{color: '#3d474f', marginTop: "10px"}}>Groups:</div>
			<div className={'ll_h_c'}>
				{this.renderNewLevel()}
				{this.renderGroupNames()}
			</div>
			{this.renderAddGroup()}
			{this.renderCustomFields()}
			{this.renderLoader()}
		</>
	}
}
